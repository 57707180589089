import React, { useEffect } from "react";
import Failure from "./../Images/Failure.json"; // Ensure this path is correct
// import LottiePlayer from "./LottiePlayer"; // Ensure this path is correct
import Lottie from "react-lottie-player";
import { useNavigate } from "react-router-dom";
import { load } from "@cashfreepayments/cashfree-js";
import { useDispatch } from "react-redux";
import { postPaymentReq } from "../redux/actions/common";

function FailurePayment() {
    const navigate = useNavigate();
    const dispatch=useDispatch();
  //   useEffect(() => {
  //     setTimeout(() => {
  //       navigate("/user");
  //     }, 5000);
  //   }, []);
  //   const handlePayment = () => {
  //     navigate("/user");
  //   };
  let cashfree;
  var initializeSDK = async function() {
    cashfree = await load({
      mode: "production"
    });
  };
  initializeSDK();

  const handlePayment = session => {
    // if (window.Cashfree && isSdkReady) {
    //   const cashfree = new window.Cashfree();
    //   const paymentConfig = {
    //     session: sessionId,
    //     mode: "DROPIN",
    //     onSuccess: (data) => {
    //       console.log("Payment Successful", data);
    //       // Handle payment success
    //     },
    //     onFailure: (error) => {
    //       console.log("Payment Failed", error);
    //       // Handle payment failure
    //     },
    //     onClose: () => {
    //       console.log("Payment window closed");
    //     },
    //   };

    //   cashfree.init(paymentConfig);
    //   cashfree.redirect();
    // } else {
    //   console.log("Cashfree SDK not loaded yet, please wait...");
    // }
    console.log("session123", session);
    let checkoutOptions = {
      paymentSessionId: session?.session_id,
      redirectTarget: "_self"
    };
    cashfree.checkout(checkoutOptions);
  };
  const handleFailure = () => {
    dispatch(
      postPaymentReq({
        amount: 99,
        details: "Payment for PAN verification",
        successAction: res => {
          console.log("Payment session created", res);
          handlePayment(res); // Pass session ID to payment
        }
      })
    );
  };
  return (
    <div className="flex flex-col items-center justify-center h-screen gap-3 p-4">
      {/* <LottiePlayer animationData={Success} /> */}
      <div className="w-1/2 text-center text-30 text-pinkV1 font-700">Payment Failed</div>
      <Lottie loop animationData={Failure} play style={{ width: 150, height: 150 }} />
      {/* <div className="w-1/2 text-center text-white text-17 ">Please try Again </div> */}
      <button type="submit" className={`px-4 cursor-pointer py-2 text-black rounded gradient-btn`} onClick={handleFailure}>
        Please try Again{" "}
      </button>
    </div>
  );
}

export default FailurePayment;
