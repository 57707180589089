import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { UserScore } from "./containers/UserScore/UserScore";
import Login from "./containers/Login";
import PrivateRoute from "./Components/PrivateRoutes";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import { HomePage } from "./containers/HomePage";
import AddUserDetails from "./containers/UserDetails/AddUserDetails";
import { ProfilePage } from "./containers/ProfilePage";
import Privacy from "./containers/Privacy";
import RefundPolicy from "./containers/RefundPolicy";
import TnC from "./containers/TnC";
import ContactUs from "./containers/ContactUs";
import SuccessPayment from "./Components/SuccessPayment";
import FailurePayment from "./Components/Failure";
import Processing from "./Components/Processing";
import GoogleAnalytics from "./utils/GaEvent";

const AppRoutes = () => {
  return (
    <Router>
      {/* <Header /> */}
      {/* <GoogleAnalytics /> */}
      <Routes>
        {/* Public route */}
        <Route path="/landing/:lang" element={<HomePage />} />
        <Route path="/landing" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/terms-and-conditions" element={<TnC />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/success" element={<SuccessPayment />} />
        <Route path="/failure" element={<FailurePayment />} />
        <Route path="/processing" element={<Processing />} />
        <Route path="/user" element={<AddUserDetails />} />

        {/* Private route */}
        <Route
          path="/"
          element={
            // <PrivateRoute>
            <UserScore />
            // </PrivateRoute>
          }
        />

        <Route
          path="/score"
          element={
            <PrivateRoute>
              <UserScore />
            </PrivateRoute>
          }
        />

        {/* <Route
          path="/home"
          element={
            <PrivateRoute>
              <HomePage />
            </PrivateRoute>
          }
        /> */}

        <Route
          path="/user"
          element={
            <PrivateRoute>
              <AddUserDetails />
            </PrivateRoute>
          }
        />

        {/* Add more private routes like this */}
        {/* <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} /> */}
      </Routes>
      {/* <Footer /> */}
    </Router>
  );
};

export default AppRoutes;
