import React from "react";

const CustomInput = ({
  label,
  value,
  onChange,
  placeholder,
  isNumeric,
  error,
  ...props
}) => {
  const handleNumericChange = (e) => {
    const newValue = e.target.value;
    if (/^\d*$/.test(newValue)) {
      onChange(e);
    }
  };
  return (
    <div className="relative">
      <label className="absolute left-2 -top-3 px-2 text-sm text-white rounded-2xl bg-bdhao-clr">
        {label}
      </label>
      <input
        type="text"
        value={value} // Controlled input value
        onChange={isNumeric ? handleNumericChange : onChange} // Apply numeric change handler if isNumeric is true
        inputMode={isNumeric ? "numeric" : "text"} // Set input mode for mobile number
        pattern={isNumeric ? "[0-9]*" : undefined} // Pattern ensures only digits for numeric inputs
        className={`px-4 py-3 w-full text-white bg-black rounded-2xl border-2 border-gray-400 focus:outline-none focus:ring-2 focus:ring-white ${
          error ? "border-pinkV1 focus:outline-none" : ""}`}
        placeholder={placeholder}
        {...props} // Spreading any additional props like type, id, etc.
      />
      {error && <span className="px-4 mt-1 text-14 text-pinkV1">{error}</span>}
    </div>
  );
};

export default CustomInput;
