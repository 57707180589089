import React, { useEffect } from "react";
import ProcessingLot from "./../Images/Processing.json";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getPaymentReq } from "../redux/actions/common";
import Lottie from "react-lottie-player";

function Processing() {
  const getOrderIdFromURL = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("order_id"); // or whatever the parameter is called in your URL
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const orderId = getOrderIdFromURL(); // Extract orderId from URL
    if (orderId) {
      // Call API to check the payment status
      checkPaymentStatus(orderId);
    }
  }, []);

  const checkPaymentStatus = orderId => {
    dispatch(
      getPaymentReq({
        orderId: orderId,
        successAction: res => {
          const paymentDetails = res;
          let orderStatus;
          if (paymentDetails.order_status === "PAID") {
            orderStatus = "Success";
            navigate("/success");
          } else {
            navigate("/failure");
            // orderStatus = "Failure";
            // Redirect or update the UI to show failure
          }
        }
      })
    );
  };
  return (
    <div className="flex flex-col items-center justify-center h-screen gap-1 p-4">
      <div className="w-1/2 text-center text-white text-17 font-700">Processing Payment</div>
      <div className="w-1/2 text-center text-white text-17 font-700">Please wait</div>
      <Lottie loop animationData={ProcessingLot} play style={{ width: 150, height: 150 }} />
    </div>
  );
}

export default Processing;
