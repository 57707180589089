import React, { useEffect } from "react";
import Success from "./../Images/Success.json"; // Ensure this path is correct
// import LottiePlayer from "./LottiePlayer"; // Ensure this path is correct
import Lottie from "react-lottie-player";
import { useNavigate } from "react-router-dom";

function SuccessPayment() {
    const navigate=useNavigate()
    useEffect(() => {
        setTimeout(() => {
            navigate('/score') 
        },7000)
    },[])
  return (
    <div className="flex flex-col items-center justify-center h-screen gap-3 p-4">
      <div className="w-1/2 text-center text-customGold text-30 font-700">Payment Successful</div>
      <Lottie loop animationData={Success} play style={{ width: 150, height: 150 }} />
      <div className="w-1/2 text-center text-white text-17 ">Congratulation on your score improvement Journey </div>
    </div>
  );
}

export default SuccessPayment;
