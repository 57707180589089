import axios from "axios";
const { NODE_ENV } = process.env;
const isProd = NODE_ENV === "production";
const BASE_URL = process.env.REACT_APP_API_URL;

const URL_HELPER = {
  SEND_OTP: isProd ? `${BASE_URL}/api/public/request-otp` : "/api/public/request-otp",
  VERIFY_OTP: isProd ? `${BASE_URL}/api/public/verify-otp` : "/api/public/verify-otp",
  FETCH_USER_DATA: isProd ? `${BASE_URL}/api/private/fetch-report` : "/api/private/fetch-report",
  USER_DETAILS: isProd ? `${BASE_URL}/api/private/onboarding` : "/api/private/onboarding",
  POST_PAYMENT: isProd ? `${BASE_URL}/api/private/create-payment-order` : "/api/private/create-payment-order",
  GET_PAYMENT: isProd ? `${BASE_URL}/api/private/order/` : "/api/private/order/",
  USER_PROFILE: isProd ? `${BASE_URL}/api/private/profile` : "/api/private/profile",
  ONBOARDING_DETAILS_V2: isProd ? `${BASE_URL}/api/public/onboarding/v2` : "/api/public/onboarding/v2",

};

const bearerToken = () => {
  if (typeof localStorage !== "undefined") {
    const token = JSON.parse(localStorage.getItem("userToken"));
    return typeof token === "string" ? `Bearer ${token}` : token?.token ? `Bearer ${token?.token}` : false;
  }
  return false;
};

const PUB_HEADER = {
  headers: {
    // Accept: "*/*",
    "Content-Type": "application/json",
    "Accept-Encoding": "gzip,deflate,br"
  }
};

const HEADER = {
  headers: {
    // Accept: "*/*",
    "Content-Type": "application/json",
    "Accept-Encoding": "gzip,deflate,br",
    Authorization: bearerToken()
  }
};
console.log("head", HEADER);

// export const fetchUserFromAPI = async () => {
//   const response = await fetch("https://dummyjson.com/todos");
//   console.log("check1234");

//   if (!response.ok) {
//     throw new Error("Failed to fetch user data");
//   }
//   console.log("check1234");
//   const data = await response.json();
//   return data;
// };

export const postSendOtpApi = async payload => {
  try {
    const response = await axios.post(`${URL_HELPER.SEND_OTP}`, payload, PUB_HEADER);
    return response;
  } catch (err) {
    console.log("check321", err);
    throw err;
  }
};

export const postVerifyOtpApi = async payload => {
  try {
    const response = await axios.post(`${URL_HELPER.VERIFY_OTP}`, payload, PUB_HEADER);
    return response;
  } catch (err) {
    throw err;
  }
};

export const fetchUserDataApi = async token => {
  const newHeaders = { ...HEADER };
  if (token) {
    newHeaders.headers["Authorization"] = `Bearer ${token}`;
  }
  try {
    bearerToken();
  } catch (err) {
    console.log(err);
  }
  try {
    const response = await axios.get(`${URL_HELPER.FETCH_USER_DATA}`, newHeaders);
    return response;
  } catch (err) {
    throw err;
  }
};

export const postUserDetailsApi = async (payload, token) => {
  const newHeaders = { ...HEADER };
  if (token) {
    newHeaders.headers["Authorization"] = `Bearer ${token}`;
  }
  try {
    bearerToken();
  } catch (err) {
    console.log(err);
  }
  try {
    const response = await axios.post(`${URL_HELPER.USER_DETAILS}`, payload, newHeaders);
    return response;
  } catch (err) {
    console.log("check321", err);
    throw err;
  }
};

export const postPaymentApi = async (payload, token) => {
  const newHeaders = { ...HEADER };
  if (token) {
    newHeaders.headers["Authorization"] = `Bearer ${token}`;
  }
  try {
    bearerToken();
  } catch (err) {
    console.log(err);
  }
  try {
    const response = await axios.post(`${URL_HELPER.POST_PAYMENT}`, payload, newHeaders);
    return response;
  } catch (err) {
    console.log("check321", err);
    throw err;
  }
};

export const getPaymentApi = async (id, token) => {
  const newHeaders = { ...HEADER };
  if (token) {
    newHeaders.headers["Authorization"] = `Bearer ${token}`;
  }
  try {
    bearerToken();
  } catch (err) {
    console.log(err);
  }
  try {
    const response = await axios.get(`${URL_HELPER.GET_PAYMENT}${id}`, newHeaders);
    return response;
  } catch (err) {
    console.log("check321", err);
    throw err;
  }
};



export const getUserProfileApi = async (token) => {
  const newHeaders = { ...HEADER };
  if (token) {
    newHeaders.headers["Authorization"] = `Bearer ${token}`;
  }
  try {
    bearerToken();
  } catch (err) {
    console.log(err);
  }
  try {
    const response = await axios.get(`${URL_HELPER.USER_PROFILE}`, newHeaders);
    return response;
  } catch (err) {
    console.log("check321", err);
    throw err;
  }
};


export const postOnBoardingApi = async (payload, token) => {
  const newHeaders = { ...HEADER };
  // if (token) {
  //   newHeaders.headers["Authorization"] = `Bearer ${token}`;
  // }
  // try {
  //   bearerToken();
  // } catch (err) {
  //   console.log(err);
  // }
  try {
    const response = await axios.post(`${URL_HELPER.ONBOARDING_DETAILS_V2}`, payload, newHeaders);
    return response;
  } catch (err) {
    console.log("check321", err);
    throw err;
  }
};
