import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CustomInput from "../../Components/Input";
import {
  getPaymentReq,
  getUserProfileReq,
  postUserOnboardingReq,
} from "../../redux/actions/common";
import { useNavigate } from "react-router-dom";
import { load } from "@cashfreepayments/cashfree-js";
import Lottie from "react-lottie-player";
import ProcessingLot from "./../../Images/Processing.json";
import { trackCustomEvent } from "../../utils/methods";

// import BottomModalWithCashfree from "../../Components/NewModal";

const AddUserDetails = () => {
  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [error, setError] = useState("");
  const [pan, setPan] = useState("");
  const [panError, setPanError] = useState("");
  const [isSdkReady, setSdkReady] = useState(true); // Track SDK loading
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [responseNew, setResponse] = useState(null);
  const [pageLoading, setPageLoading] = useState(true);
  const searchParams = new URLSearchParams(window.location.search);
  console.log("checkseach", searchParams);
  const utmSource = searchParams.get("utm_source");

  useEffect(() => {
    dispatch(
      getUserProfileReq({
        successAction: (res) => {
          if (res?.paymentStatus === "PAID") {
            navigate("/score");
            setPageLoading(false);
          } else {
            setPageLoading(false);
          }
        },
        errorAction: () => {
          setPageLoading(false);
        },
      })
    );
    if (pageLoading) {
      setTimeout(() => {
        setPageLoading(false);
      }, 4000);
    }
  }, []);

  useEffect(() => {
    if (pageLoading) {
      setTimeout(() => {
        setPageLoading(false);
      }, 4000);
    }
  }, [pageLoading]);

  const getOrderIdFromURL = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("order_id"); // or whatever the parameter is called in your URL
  };
  const [loadEvents, setLoadEvents] = useState(false);

  function initializeGA() {
    const GA_TRACKING_ID = "G-JQ17N2B4P6"; // Replace with your GA tracking ID
    const existingScript = document.getElementById("gtmEvents");
    if (!existingScript) {
      const script = document.createElement("script");
      script.async = true;
      script.id = "gtmEvents";
      script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
      document.head.appendChild(script);

      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        window.gtag = gtag;

        gtag("js", new Date());
        gtag("config", GA_TRACKING_ID, {
          page_path: window.location.pathname,
        });
        setLoadEvents(true);
      };
    }
  }

  const validateMobileNumber = (number) => {
    const regex = /^[6-9]\d{9}$/;
    if (!regex.test(number)) {
      return {
        code: -1,
        message: "Please enter a valid 10-digit Indian mobile number",
      };
    }
    return { code: 0, message: "Valid mobile number" };
  };
  useEffect(() => {
    const orderId = getOrderIdFromURL(); // Extract orderId from URL
    if (orderId) {
      // Call API to check the payment status
      checkPaymentStatus(orderId);
    }
    initializeGA();
  }, []);

  useEffect(() => {
    console.log("utm", utmSource);
    try {
      if (window.gtag && loadEvents) {
        if (utmSource) {
          trackCustomEvent("UserDetails", utmSource);
          setLoadEvents(false);
        }
      } else {
        initializeGA();
      }
    } catch (e) {
      console.log("gaError", e);
    }
  }, [utmSource, loadEvents]);

  function autoCapitalizePAN(panInput) {
    // Remove any non-alphanumeric characters (if needed)
    const cleanedInput = panInput.replace(/[^a-zA-Z0-9]/g, "");

    // Convert alphabetic characters to uppercase
    return cleanedInput.toUpperCase();
  }

  function formatIndianPhoneNumber(phoneNumber) {
    // Remove any non-digit characters from the phone number
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");

    // Check if the cleaned number starts with '91' and is 12 digits long (valid with country code)
    if (
      cleanedPhoneNumber.startsWith("91") &&
      cleanedPhoneNumber.length === 12
    ) {
      // Return in the format +91 followed by the 10 digits
      return "+91" + cleanedPhoneNumber.slice(2); // Slice out the '91' prefix and prepend '+91'
    }

    // Check if the number is 10 digits long (valid without country code)
    else if (cleanedPhoneNumber.length === 10) {
      // Return in the format +91 followed by the 10 digits
      return "+91" + cleanedPhoneNumber;
    }

    // Check if the number starts with '0' and is 11 digits long (domestic number starting with 0)
    else if (
      cleanedPhoneNumber.startsWith("0") &&
      cleanedPhoneNumber.length === 11
    ) {
      // Remove the '0' and prepend '+91'
      return "+91" + cleanedPhoneNumber.slice(1);
    }

    // Invalid number cases
    else {
      return "Invalid number. Enter a 10-digit number or a valid number with country code.";
    }
  }

  const checkPaymentStatus = (orderId) => {
    dispatch(
      getPaymentReq({
        orderId: orderId,
        successAction: (res) => {
          const paymentDetails = res;
          let orderStatus;
          if (paymentDetails.order_status === "PAID") {
            orderStatus = "Success";
            navigate("/success");
          } else {
            navigate("/failure");
            // orderStatus = "Failure";
            // Redirect or update the UI to show failure
          }
        },
      })
    );
  };

  // useEffect(() => {
  //   const loadCashfreeSDK = () => {
  //     const script = document.createElement("script");
  //     script.src = "https://sdk.cashfree.com/js/v3/cashfree.js";
  //     script.async = true;

  //     script.onload = () => {
  //       console.log("Cashfree SDK Loaded");
  //       setSdkReady(true); // SDK is ready
  //     };

  //     script.onerror = () => {
  //       console.error("Failed to load Cashfree SDK");
  //     };

  //     document.body.appendChild(script);
  //   };

  //   loadCashfreeSDK();
  // }, []);

  const validatePAN = (panNumber) => {
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    return panRegex.test(panNumber);
  };

  const [isOpen, setIsOpen] = useState(false);

  let cashfree;
  let flag = false;
  var initializeSDK = async function () {
    if (!flag) {
      cashfree = await load({
        mode: "production",
      });
      flag = true;
    }
  };
  // useEffect(() => {
  initializeSDK();
  // }, []);

  const handlePayment = (session) => {
    // if (window.Cashfree && isSdkReady) {
    //   const cashfree = new window.Cashfree();
    //   const paymentConfig = {
    //     session: sessionId,
    //     mode: "DROPIN",
    //     onSuccess: (data) => {
    //       console.log("Payment Successful", data);
    //       // Handle payment success
    //     },
    //     onFailure: (error) => {
    //       console.log("Payment Failed", error);
    //       // Handle payment failure
    //     },
    //     onClose: () => {
    //       console.log("Payment window closed");
    //     },
    //   };

    //   cashfree.init(paymentConfig);
    //   cashfree.redirect();
    // } else {
    //   console.log("Cashfree SDK not loaded yet, please wait...");
    // }
    // console.log("session123", session);
    // let checkoutOptions = {
    //   paymentSessionId: session?.session_id,
    //   redirectTarget: "_self"
    // };
    // cashfree.checkout(checkoutOptions);
    // let checkoutOptions = {
    //   paymentSessionId: session?.session_id,
    //   redirectTarget: document.getElementById("cf_checkout"),
    //   appearance: {
    //     width: "425px",
    //     height: "300px"
    //   }
    // };
    // setLoading(false);
    // cashfree.checkout(checkoutOptions).then(result => {
    //   if (result.error) {
    //     // This will be true when there is any error during the payment
    //     console.log("There is some payment error, Check for Payment Status");
    //     console.log(result.error);
    //   }
    //   if (result.redirect) {
    //     // This will be true when the payment redirection page couldnt be opened in the same window
    //     // This is an exceptional case only when the page is opened inside an inAppBrowser
    //     // In this case the customer will be redirected to return url once payment is completed
    //     console.log("Payment will be redirected");
    //   }
    //   if (result.paymentDetails) {
    //     // This will be called whenever the payment is completed irrespective of transaction status
    //     console.log("Payment has been completed, Check for Payment Status");
    //     console.log(result.paymentDetails.paymentMessage);
    //   }
    // });

    let checkoutOptions = {
      paymentSessionId: session?.session_id,
      redirectTarget: document.getElementById("cf_checkout"),
      appearance: {
        width: "425px",
        height: "700px",
      },
    };
    cashfree.checkout(checkoutOptions).then((result) => {
      if (result.error) {
        // This will be true when there is any error during the payment
        console.log("There is some payment error, Check for Payment Status");
        console.log(result.error);
      }
      if (result.redirect) {
        // This will be true when the payment redirection page couldnt be opened in the same window
        // This is an exceptional case only when the page is opened inside an inAppBrowser
        // In this case the customer will be redirected to return url once payment is completed
        console.log("Payment will be redirected");
      }
      if (result.paymentDetails) {
        // This will be called whenever the payment is completed irrespective of transaction status
        console.log("Payment has been completed, Check for Payment Status");
        console.log(result.paymentDetails.paymentMessage);
      }
    });
  };

  const handleDetails = () => {
    setPanError("");
    // Validate PAN before proceeding
    if (!validatePAN(pan)) {
      setPanError("Enter valid PAN");
      return;
    }

    setLoading(true);
    trackCustomEvent(
      "UserDetails",
      utmSource ? `${utmSource}_buy_click` : "buy_click"
    );
    dispatch(
      postUserOnboardingReq({
        firstName: name,
        panCard: pan,
        mobileNumber: formatIndianPhoneNumber(mobileNumber),
        lastName: "",
        successAction: (res) => {
          dispatch(
            getUserProfileReq({
              successAction: (resNew) => {
                if (resNew?.paymentStatus === "PAID") {
                  navigate("/score");
                  setPageLoading(false);
                } else {
                  setResponse(res);
                  handlePayment(res);
                }
              },
              errorAction: () => {
                setResponse(res);
                handlePayment(res);
              },
            })
          );
          // navigate("/score");
          // Pass session ID to payment
          // dispatch(
          //   postPaymentReq({
          //     amount: 99,
          //     details: "Payment for PAN verification",
          //     successAction: res => {
          //       console.log("Payment session created", res);
          //       setIsOpen(true);
          //       console.log("checkuser123");
          //       setResponse(res);
          //       handlePayment(res); // Pass session ID to payment
          //     }
          //   })
          // );
        },
      })
    );
  };

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 4000);
    }
  }, [loading]);

  if (pageLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-screen gap-1 p-4">
        <Lottie
          loop
          animationData={ProcessingLot}
          play
          style={{ width: 150, height: 150 }}
        />
      </div>
    );
  }

  return (
    <div className="relative flex flex-col items-center justify-center h-screen px-6 py-14 rounded-2xl po:items-start md:h-1/2 md:m-10p md:bg-dark-bl-clr">
      <div className="max-w-md text-white text-30 font-700 h-max w-87p">
        Please enter Name & PAN number
      </div>
      <div className="flex flex-col justify-between w-full h-full max-w-md gap-10 pt-20 shadow-lg rounded-2xl bg-bdhao-clr md:bg-transparent">
        <div className="flex flex-col gap-10">
          <CustomInput
            label="Name"
            placeholder="Name as mentioned in PAN"
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            id="name"
            name="name"
          />
          <div>
            {/* {error && (
              <div className="p-2 mb-4 text-red-700 bg-red-100 rounded">
                {error}
              </div>
            )} */}
            <CustomInput
              label="Phone Number"
              placeholder="Linked Phone Number"
              value={mobileNumber}
              onChange={(e) => {
                setMobileNumber(e.target.value);
                // if (e?.target?.value?.length > 9) {
                //   // setDisabled(false);
                //   // handleLogin(e?.target?.value);
                //   // setShowOtp(true);
                // } else {
                //   setShowOtp(false);
                // }
              }}
              type="text"
              id="mobile"
              name="mobile"
              maxLength="10"
              isNumeric={true} // Set this to true for number-only input
              error={error}
            />
          </div>
          <div>
            <CustomInput
              label="PAN"
              placeholder="Enter PAN number"
              value={pan}
              onChange={(e) => setPan(autoCapitalizePAN(e.target.value))}
              type="text"
              id="pan"
              name="pan"
              maxLength="10"
              error={panError}
            />
            {panError && <div className="mb-4 text-red-600">{panError}</div>}
          </div>
        </div>

        <button
          type="submit"
          className={`px-4 py-3 flex gap-1 items-center justify-center cursor-pointer font-semibold text-black rounded ${
            isSdkReady ? "gradient-btn" : "bg-white opacity-60"
          } ${
            loading ? "opacity-60 pointer-events-none" : ""
          } po:fixed po:bottom-6 left-5 right-5`}
          onClick={handleDetails}
          // disabled={!isSdkReady} // Disable button until SDK is ready
        >
          {loading ? (
            <svg
              className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              />
              <path
                className="opacity-75"
                fill="#000000"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          ) : null}
          {isSdkReady ? "Buy Now" : "Loading..."}
        </button>
      </div>
      {console.log("checkuser", isOpen)}

      {/* {isOpen && (
        <>
          {console.log("checkuser", isOpen)}
          <BottomModalWithCashfree sessionId={responseNew.session_id} setIsOpen={setIsOpen} isOpen={isOpen} />
        </>
      )} */}
    </div>
  );
};

export default AddUserDetails;
