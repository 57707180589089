import "./App.css";
// import { useDispatch, useSelector } from "react-redux";
// import { useEffect } from "react";
// import { fetchUser } from "./redux/actions/common";
import AppRoutes from "./Routes";
import "@fontsource/readex-pro"; // Defaults to weight 400
import "@fontsource/readex-pro/500.css"; // Weight 500
import "@fontsource/readex-pro/700.css"; // Weight 700
// import { useEffect } from "react";
import { useEffect } from "react";

function App() {
  // const { loading, user, error } = useSelector((state) => state.common);
  // console.log("loading", loading, user, error);

  // const loadCashfreeSDK = () => {
  //   const script = document.createElement("script");
  //   script.src = "https://sdk.cashfree.com/js/v3/cashfree.js";
  //   script.async = true;
  //   document.body.appendChild(script);
  // };

  // useEffect(() => {
  //   loadCashfreeSDK();
  // }, []);

  // let cashfree;
  // var initializeSDK = async function() {
  //   cashfree = await load({
  //     mode: "production"
  //   });
  // };
  // useEffect(() => {
  //   initializeSDK();
  // }, []);

  // analytics.js
  function initializeGA() {
    const GA_TRACKING_ID = "G-JQ17N2B4P6"; // Replace with your GA tracking ID

    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      window.gtag = gtag;

      gtag("js", new Date());
      gtag("config", GA_TRACKING_ID, {
        page_path: window.location.pathname,
      });
    };
  }
  useEffect(() => {
    initializeGA();
  }, []);

  return (
    <div className="">
      <AppRoutes />
    </div>
  );
}

export default App;
