import { createAction } from "redux-actions";
import {
  SENT_OTP_REQ,
  SENT_OTP_SUCCESS,
  SENT_OTP_FAILURE,
  VERIFY_OTP_REQ,
  VERIFY_OTP_FAILURE,
  VERIFY_OTP_SUCCESS,
  FETCH_USER_DATA_REQ,
  FETCH_USER_DATA_SUCCESS,
  FETCH_USER_DATA_FAILURE,
  POST_USER_DETAILS_REQ,
  POST_USER_DETAILS_SUCCESS,
  POST_USER_DETAILS_FAILURE,
  POST_PAYMENT_REQ,
  POST_PAYMENT_SUCCESS,
  POST_PAYMENT_FAILURE,
  GET_PAYMENT_REQ,
  GET_PAYMENT_FAILURE,
  GET_PAYMENT_SUCCESS,
  CHECK_PROFILE_STATUS_REQ,
  CHECK_PROFILE_STATUS_SUCCESS,
  CHECK_PROFILE_STATUS_FAILED,
  POST_USER_ONBOARDING_REQ,
  POST_USER_ONBOARDING_SUCCESS,
  POST_USER_ONBOARDING_FAILURE
} from "../actionTypes/common";

export const sendOtpReq = createAction(SENT_OTP_REQ);
export const sendOtpSuccess = createAction(SENT_OTP_SUCCESS);
export const sendOtpFailure = createAction(SENT_OTP_FAILURE);
export const verifyOtpReq = createAction(VERIFY_OTP_REQ);
export const verifyOtpSuccess = createAction(VERIFY_OTP_SUCCESS);
export const verifyOtpFailure = createAction(VERIFY_OTP_FAILURE);

export const fetchUserDataReq = createAction(FETCH_USER_DATA_REQ)
export const fetchUserDataSuccess = createAction(FETCH_USER_DATA_SUCCESS)
export const fetchUserDataFailure = createAction(FETCH_USER_DATA_FAILURE)

export const postUserDetailsReq=createAction(POST_USER_DETAILS_REQ)
export const postUserDetailsSuccess=createAction(POST_USER_DETAILS_SUCCESS)
export const postUserDetailsFailure = createAction(POST_USER_DETAILS_FAILURE)

export const postPaymentReq=createAction(POST_PAYMENT_REQ)
export const postPaymentSuccess=createAction(POST_PAYMENT_SUCCESS)
export const postPaymentFailure = createAction(POST_PAYMENT_FAILURE)


export const getPaymentReq=createAction(GET_PAYMENT_REQ)
export const getPaymentSuccess=createAction(GET_PAYMENT_SUCCESS)
export const getPaymentFailure = createAction(GET_PAYMENT_FAILURE)


export const getUserProfileReq = createAction(CHECK_PROFILE_STATUS_REQ)
export const getUserProfileSuccess=createAction(CHECK_PROFILE_STATUS_SUCCESS)
export const getUserProfileFailed=createAction(CHECK_PROFILE_STATUS_FAILED)



export const postUserOnboardingReq = createAction(POST_USER_ONBOARDING_REQ);
export const postUserOnboardingSuccess = createAction(POST_USER_ONBOARDING_SUCCESS);
export const postUserOnboardingFailure = createAction(POST_USER_ONBOARDING_FAILURE);
